import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { termsAndConditionsUSMarch2021 } from "../utilities/termsAndConditionsUS";

const TermsAndConditionsUSMarch2021 = ({ location }) => {
  return (
    <Layout location={location}>
      {/* Header */}
      <section>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-4 md:pt-40 md:pb-4">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
              <h1 className="h1 mb-4">Service Agreement Terms & Conditions</h1>
              <p className="text-xl text-gray-600">
                This Service Agreement (“Agreement”) is between Cord
                Technologies, Inc. and registered in Delaware at 251 Little Falls Drive, City of Wilmington, DE 19808, United States of America
                (“Encord”) and and the entity identified as Client in the
                Statement of Work (“Client”) (each, a “Party” and together, the
                “Parties”). This Agreement is effective as of the Service Order
                Effective Date of the initial Statement of Work (“Effective
                Date”).
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*  Body of text  */}
      <section>
        <div className="max-w-5xl mx-auto px-4 sm:px-6">
          <div className="pb-12 md:pb-20 privacy-content">
            <ol className="max-w-3xl mx-auto">
              {termsAndConditionsUSMarch2021.map(({ title, points }, index) => (
                <>
                  <li className="h3 mb-3" key={index}>
                    {title}
                    <ol className="max-w-3xl mx-auto mt-2">
                      {points.map((data, index) => (
                        <>
                          <li
                            key={index}
                            className="text-lg font-normal text-gray-600 mb-8"
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: data?.point,
                              }}
                            />
                          </li>
                        </>
                      ))}
                    </ol>
                  </li>
                </>
              ))}
            </ol>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TermsAndConditionsUSMarch2021;

export const Head = () => (
  <SEO
    title="Service Agreement Terms & Conditions US March 2021"
    description="Encord Terms of Business US march 2023"
  />
);
